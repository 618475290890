import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GolazoTestimonials = () => {
  return (
	<section className="cs-testimonials">
		<Container>			
			<Row className="justify-content-center">
				<Col lg={7}>
					<div className="content text-center">
						<h2 className="sub-title golazo">"We felt like working with our own in-house UX design team!"</h2>
						<p className="cs-sub-text first text-white">
                            We selected WowMakers after an extensive search for a reliable UX agency for the design and development of Golazo app. They designed intuitive user journeys, information architecture and wireframes. Their engineers completed the software development (both iOS and Android) well within the promised deadline. Overall, it was a wonderful experience working with WowMakers. I will definitely go back for future requirements.
						</p>
						<div className="client golazo">
							<p>Will Morrison, Head of Brand Experience, Golazo</p>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	</section>
  )
}

export default GolazoTestimonials
