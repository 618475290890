import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoSolutions = () => {
  return (
	<section className="our-solutions">
		<Container>
            <div className="image-block">
                <Row>
                    <Col md={6} className="mb-md-0 mb-4">                        
                        <StaticImage src="../../../images/case-studies/golazo/our-solution-1.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={788} height={1199}/>
                    </Col>
                    <Col md={6}>
                        <StaticImage src="../../../images/case-studies/golazo/our-solution-2.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={788} height={1199}/>
                    </Col>	                        
                </Row>
            </div>            
			<div className="section-primary mt-5 pt-md-5 pt-0">
				<Row>
					<Col lg={2} md={4}>
						<h2 className="sub-title">
                            Our Solutions
						</h2>
					</Col>
					<Col lg={9} md={8} className="offset-lg-1">
                        <h3 className="secondary-title">Make challenging goals seem simple enough to achieve</h3>
						<p className="cs-sub-text gutter-bottom-medium">
							Nobody runs a marathon on their first day. An ideal method would be breaking the full distance into smaller sprints achievable over many days until the runner is finally fit and confident for the big race. Similarly, in the Golazo project, we made sure that the user’s goals were broken down into simple and achievable tasks. Afterall, many short term goals help you to reach the long term goal. This approach made the users confident about setting and completing goals.
						</p>
						<h3 className="secondary-title">Track and celebrate milestones to sustain the motivation</h3>
						<p className="cs-sub-text">
							Nothing is more rewarding to the user like striking off the boxes, crossing over that finish line or in this case the completion of a goal. This is also the opportunity for the user to celebrate and showcase their accomplishments among their peers.
						</p>
					</Col>
				</Row>
			</div>
		</Container>
	</section>
  )
}

export default GolazoSolutions
