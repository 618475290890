import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GolazoMain = () => {
  return (
	<div className="cs-header mobile-left-right-gutter">
		<Container>
			<Row>
				<Col xl={5} md={6} className="offset-xl-1">
					<div className="csh-content">
						<p className="mb-0 font-weight-bold">
                            <Link href="/case-studies/">Case Studies</Link> / <span className="text-white">Golazo</span>
                        </p>
						<h1>A brand new take on goal-tracking.</h1>
						<p>Golazo is a goal tracking app - be it personal or professional. The app allows you to set goals, measure progress, get reminders and celebrate milestones.</p>
					</div>
				</Col>
			</Row>
            <div className="right-bg">
                <StaticImage src="../../../images/case-studies/golazo/header-img.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={355} height={701}/>
            </div>            
		</Container>
	</div>
  )
}

export default GolazoMain
