import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoOverview = () => {
  return (
	<section className="meet-the-client">
		<Container>
			<div className="section-primary">
				<Row>
					<Col lg={2} md={4}>
						<h2 className="sub-title">
							Project Overview
						</h2>
					</Col>
					<Col lg={9} md={8} className="offset-lg-1">
						<p className="cs-sub-text first">
                            In this project, we have spent a good time patiently understanding the requirement, its target audience, pain points, solutions, existing players in the market, USPs etc.
						</p>
						<p className="cs-sub-text">We elevated the app’s discoverability and user retention by using intuitive user journeys and simple information architecture. We have created interface designs that are aesthetically stunning, easy to navigate with a consistent design system, along with engaging copy.</p>
					</Col>
				</Row>
			</div>
            <div className="image-block mt-5 pt-md-5 pt-0">
                <Row>
                    <Col md={4}>
                        <div className="image-grid first">
                            <StaticImage src="../../../images/case-studies/golazo/project-overview-1.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={466} height={920}/>                                
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="image-grid middle">
                            <StaticImage src="../../../images/case-studies/golazo/project-overview-2.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={466} height={920}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="image-grid last">
                            <StaticImage src="../../../images/case-studies/golazo/project-overview-3.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={466} height={920}/>                                
                        </div>
                    </Col>	                        
                </Row>
            </div>
		</Container>
	</section>
  )
}

export default GolazoOverview
