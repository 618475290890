import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GolazoHighlights = () => {
  return (
	<section className="our-solutions pt-0">
		<Container>            
			<div className="section-primary">
				<Row>
					<Col lg={2} md={4}>
						<h2 className="sub-title">
                            Project Highlights
						</h2>
					</Col>
					<Col lg={9} md={8} className="offset-lg-1">
                        <h3 className="secondary-title">Empathy driven data visualization</h3>
						<p className="cs-sub-text gutter-bottom-medium">
                            Every goal-tracker ever made, was a number cruncher, with stats and graphs. Instead of taking the usual route of presenting the data to the user in its true form, we got the numbers to look engaging, captivating and easy to read. We believe this visual form of data will have a positive impact on a user, every time he/she looked at their performance.
						</p>
						<h3 className="secondary-title">Unique 3D illustrations to boost the users</h3>
						<p className="cs-sub-text gutter-bottom-medium">
                            3D illustrations are known to have an immense visual impact on users. With these elements an extension from the digital world to the real world was easier to achieve. We used highly defined 3D illustrations from Alzea Arafat to give Golazo the best appearance.
						</p>
						<h3 className="secondary-title">Emoji inspired interactive icons</h3>
						<p className="cs-sub-text">
                            We didn’t have to do a lot of research to figure this out. Emojis mean emotion. And this influenced Golazo to steer to an interactive side of goal-tracking that was never seen before. We made sure that the designs used all the right emojis that users were familiar with, which helped the app connect with the audience.
						</p>                        
					</Col>
				</Row>
			</div>
		</Container>
	</section>
  )
}

export default GolazoHighlights
