import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GolazoInfo = () => {
  return (
	<section className="cs-information">
		<Container>
			<Row className="row">
				<Col xl={2} md={3} className="offset-xl-1">
					<div className="details after-dot">
						<span>Project Duration</span>
						<p>2 months</p>
					</div>
				</Col>
				<Col md={2}>
					<div className="details after-dot">
						<span>Services</span>
						<p>UI/UX</p>
					</div>
				</Col>
				<Col md={3}>
					<div className="details">
						<span>Platform</span>
						<p>iOS, Android</p>
					</div>
				</Col>					
			</Row>
		</Container>
	</section>
  )
}

export default GolazoInfo
