import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoUserPersona = () => {
  return (
	<section className="timeline bg-white">
        <Container>
            <Row>
                <Col>
                    <h2 className="sub-title">User Persona</h2>
                </Col>
            </Row>
        </Container>
		<Container>            
                <Row>
                    <Col md={6} className="mb-md-0 mb-4">                       
                        <StaticImage src="../../../images/case-studies/golazo/user-persona-1.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={798} height={599}/>
                    </Col>
                    <Col md={6}>                       
                        <StaticImage src="../../../images/case-studies/golazo/user-persona-2.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={798} height={599}/>
                    </Col>                    	                        
                </Row>
		</Container>
	</section>
  )
}

export default GolazoUserPersona
