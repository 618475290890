import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/golazo.scss"
import GolazoMain from "../../components/case-studies/golazo/golazoMain"
import GolazoInfo from "../../components/case-studies/golazo/golazoInfo"
import GolazoOverview from "../../components/case-studies/golazo/golazoOverview"
import GolazoTestimonials from "../../components/case-studies/golazo/golazoTestimonials"
import GolazoGoals from "../../components/case-studies/golazo/golazoGoals"
import GolazoGridImages from "../../components/case-studies/golazo/golazoGridImages"
import GolazoSolutions from "../../components/case-studies/golazo/golazoSolutions"
import GolazoFullWidth1 from "../../components/case-studies/golazo/golazoFullWidth1"
import GolazoTimeLine from "../../components/case-studies/golazo/golazoTimeLine"
import GolazoWhatWeDid from "../../components/case-studies/golazo/golazoWhatWeDid"
import GolazoUserPersona from "../../components/case-studies/golazo/golazoUserPersona"
import GolazoInformationArchitecture from "../../components/case-studies/golazo/golazoInformationArchitecture"
import GolazoWireframes from "../../components/case-studies/golazo/golazoWireframes"
import GolazoHighlights from "../../components/case-studies/golazo/golazoHighLights"
import GolazoFullWidth2 from "../../components/case-studies/golazo/golazoFullWidth2"
import GolazoMoreCs from "../../components/case-studies/golazo/golazoMoreCs"
import DirectContact from "../../components/global/directContact"
import csGolazoFeaturedImage from "../../../static/home/wowmakers.jpeg"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csGolazoFeaturedImage}
      title="Golazo Case Study | UX/UI Design | Digital Products"
      description="Golazo is an app designed to create and sustain personal and career goals. WowMaker's human-centred design approach made goal tracking a spectacular experience."
    />
  </> 
)

const Golazo = () => {
    return (
      <Layout>
        <Seo
          bid="cs-golazo"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <GolazoMain />
        <GolazoInfo />
        <GolazoOverview />
        <GolazoTestimonials />
        <GolazoGoals />
        <GolazoGridImages />
        <GolazoSolutions />
        <GolazoFullWidth1 />
        <GolazoTimeLine />
        <GolazoWhatWeDid />
        <GolazoUserPersona />
        <GolazoInformationArchitecture />
        <GolazoWireframes />
        <GolazoHighlights />
        <GolazoFullWidth2 />
        <GolazoMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default Golazo
