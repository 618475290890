import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoFullWidth2 = () => {
  return (
	<section className="full-width-bg py-0">
		<Container fluid>
            
                <Row>
                    <Col xs={12} className="px-0">                       
                        <StaticImage src="../../../images/case-studies/golazo/project-highlights.jpeg" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1087}/>
                    </Col>	                        
                </Row>
		</Container>
	</section>
  )
}

export default GolazoFullWidth2
