import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoTimeLine = () => {
  return (
	<section className="timeline bg-white">
        <Container>
            <Row>
                <Col>
                    <h2 className="sub-title">Timeline</h2>
                </Col>
            </Row>
        </Container>
		<Container fluid>            
                <Row>
                    <Col xs={12} className="px-0">                       
                        <StaticImage src="../../../images/case-studies/golazo/timeline.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={880}/>
                    </Col>	                        
                </Row>
		</Container>
	</section>
  )
}

export default GolazoTimeLine
