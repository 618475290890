import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoGoals = () => {
  return (
	<section className="problem-project-goals">
		<Container>
			<div className="section-primary">			
				<Row className="row">
					<Col md={2} className="col-md-2">
						<h2 className="sub-title">The Problem</h2>
					</Col>
					<Col md={9} className="offset-md-1">
						<h3 className="secondary-title">Design a goal tracker app that is simple to use on a daily basis without missing the big picture</h3>
						<p className="cs-sub-text">
                            There are plenty of task management and goal-setting methods out there, and yet many people still relied on Excel sheets, sticky notes and many such DIY methods. This resulted in missed goals, inefficient use of time and loss of information across tools, which eventually defeated its purpose.
						</p>
					</Col>
				</Row>
			</div>			
            <div className="image-block mt-5 pt-md-5 pt-0">
                <Row>
                    <Col md={6}>
                        <StaticImage src="../../../images/case-studies/golazo/the-probelm-1.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={718}/>
                    </Col>
                    <Col md={6}>
                        <StaticImage src="../../../images/case-studies/golazo/the-probelm-2.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={701}/>
                    </Col>                        	                        
                </Row>
            </div>
			<div className="section-primary mt-5 pt-md-5 pt-0">
				<Row>
					<Col lg={2} md={4}>
						<h2 className="sub-title">
                            Project Goals
						</h2>
					</Col>
					<Col lg={9} md={8} className="offset-lg-1">
                        <h3 className="secondary-title">Create an app that helps people prioritize goals and accomplish them</h3>
						<p className="cs-sub-text gutter-bottom-medium">
							Creating an account and setting the goals should be a no brainer. As per research, many users of productivity apps are unable to focus on one goal at a time and randomly choose goals to work on, which would get piled up later. Setting goals is not the challenge, measuring the progress and achieving them efficiently is the tough nut to crack.
						</p>
						<h3 className="secondary-title">Identify the challenges people face with setting goals and managing tasks</h3>
						<p className="cs-sub-text">
							Goal-completion works in two ways. Easier goals often take less time to complete, boosting the morale of the user, while the harder goals, on failing to complete, would often mess with their motivation. To overcome this, people usually set short term plans to reach their long term goals. In a scenario where the users juggle between hard and easy goals, some unnecessary confusions and dilemma takes over, disrupting the overall goal tracking experience. In this project, our objective was to break the bigger goals into smaller attainable tasks and help the users measure and manage their whole journey.
						</p>
					</Col>
				</Row>
			</div>                            
		</Container>
	</section>    
  )
}

export default GolazoGoals
