import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoGridImages = () => {
  return (
	<section className="grid-images">
		<Container>
            
                <Row>
                    <Col md={4}>
                        <div className="image-grid first">
                            <StaticImage src="../../../images/case-studies/golazo/golazo-screens-1.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={465} height={920}/>                                
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="image-grid middle">
                            <StaticImage src="../../../images/case-studies/golazo/golazo-screens-2.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={465} height={920}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="image-grid last">
                            <StaticImage src="../../../images/case-studies/golazo/golazo-screens-3.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid" width={465} height={920}/>                                
                        </div>
                    </Col>	                        
                </Row>
		</Container>
	</section>
  )
}

export default GolazoGridImages
