import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const GolazoInformationArchitecture = () => {
  return (
	<section className="Info-architechure bg-white pt-0">
        <Container>
            <Row>
                <Col>
                    <h2 className="sub-title">Information Architecture</h2>
                </Col>
            </Row>
        </Container>
		<Container fluid>            
                <Row>
                    <Col xs={12} className="px-0">                       
                        <StaticImage src="../../../images/case-studies/golazo/information-architecture.png" alt="Golazo UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={1920} height={1117}/>
                    </Col>	                        
                </Row>
		</Container>
	</section>
  )
}

export default GolazoInformationArchitecture
