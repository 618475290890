import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../../icon/buttonIcon"

const GolazoMoreCs = () => {
  return (
    <section className="home-cs-listing">
        <Container>
            <Row className="justify-content-center">
                <Col md={8} className="text-center">
                <h2 className="sub-title">
                    More Case Studies
                </h2>
                </Col>
            </Row>
            <Row className="wrapper-main">          
                <Col lg={4} className="mt-md-0 mt-3">
                    <Link to="/case-studies/tbx-trading-app/" className="home-cs-link first">
                        <div className="hcs-img">
                            <StaticImage
                            src="../../../images/case-studies/tbx-trading-app/cs-tbx-trading-app.png"
                            alt="TBX Trading App UX/UI Case studies | WowMakers"
                            placeholder="blurred"
                            width={507}
                            height={354}
                            />
                        </div>
                        <div className="hcs-content branding">
                            <span className="section-tag">UX/UI Design<span className="seperator"><span>.</span></span>Banking & FinTech</span>
                            <div className="cs-title">
                                <h3>
                                    TBX
                                </h3>
                            </div>
                            <div className="btn wowaction-btn">
                            <ButtonIcon />
                            <span>Read Case Study</span>
                            </div>
                        </div>
                </Link>
                </Col>                    
                <Col lg={4} className="mt-lg-0 mt-3">
                    <Link to="/case-studies/trax-mobile-app/" className="home-cs-link middle">
                        <div className="hcs-img">
                            <StaticImage
                            src="../../../images/case-studies/trax-mobile-app/cs-trax-bike-shop-app.png"
                            alt="Trax Mobile App UX/UI Case Study | WowMakers"
                            placeholder="blurred"
                            width={507}
                            height={354}
                            className="img-fluid"
                            />
                        </div>
                        <div className="hcs-content branding">
                            <span className="section-tag">UX/UI Design<span className="seperator"><span>.</span></span>eCommerce</span>
                            <div className="cs-title">
                            <h3>
                                TRAX
                            </h3>
                            </div>
                            <div className="btn wowaction-btn">
                            <ButtonIcon />
                            <span>Read Case Study</span>
                            </div>
                        </div>
                    </Link>
                </Col>
                <Col lg={4} className="mt-lg-0 mt-3">
                    <Link to="/case-studies/social-delivery-system/" className="home-cs-link last">
                        <div className="hcs-img">
                            <StaticImage
                            src="../../../images/case-studies/social-delivery-system/social-delivery-system.jpg"
                            alt="Social Delivery System UX/UI Case Study | WowMakers"
                            placeholder="blurred"
                            width={1014}
                            height={708}
                            />
                        </div>
                        <div className="hcs-content branding">
                            <span className="section-tag">UX/UI Design<span className="seperator"><span>.</span></span>Logistics</span>
                            <div className="cs-title">
                                <h3>
                                    Raaven
                                </h3>
                            </div>
                            <div className="btn wowaction-btn">
                            <ButtonIcon />
                            <span>Read Case Study</span>
                            </div>
                        </div>
                    </Link>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default GolazoMoreCs
